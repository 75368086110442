import { Router } from '@reach/router';
import React, { useContext } from 'react';
import PrivateRoute from '../components/routing/PrivateRoute';
import { AccountRouter } from '../protected-pages/account/AccountRouter';
import NeedMoreTimeToPayPage from '../protected-pages/need-more-time-to-pay';
import { PaymentRouter } from '../protected-pages/payment/PaymentRouter';
import ViewBillPage from '../protected-pages/payment/billing/view-bill';
import ViewAndDownloadBillsPage from '../protected-pages/payment/billing/download-multiple-bills';
import PeakTimeRebatesPage from '../protected-pages/peaktimerebates';
import { ProfileRouter } from '../protected-pages/profile/ProfileRouter';
import { ServiceRouter } from '../protected-pages/service/ServiceRouter';
import NotFoundPage from './404';
import { usePlannedDownTimeRedirect } from '../hooks/usePlannedDownTimeRedirect';
import { CheckCookiesEnabled } from '../components/browser-check/CheckCookiesEnabled';
import { ProgramsRouter } from '../protected-pages/programs/ProgramsRouter';
import TimeOfDayMain from '../protected-pages/time-of-day';
import { featureFlagsContext } from '../providers/FeatureFlagsProvider';
import ClosedAccounts from '../protected-pages/closed-accounts';
import ContactDataInterceptModal from '../components/contact-data-intercept-modal';
import useContactDataInterceptModal from '../hooks/useContactDataInterceptModal';
import ReconnectServiceMain from '../protected-pages/reconnect-service';
import { SSMRouter } from '../protected-pages/ssm/SSMRouter';
import { EssRouter } from '../protected-pages/ess/EssRouter';
import useClientProgramPromo from '../hooks/useClientProgramPromo';
import ClientProgramPromoModal from '../components/client-program-promo-modal';
import MyWaysToSaveMain from '../protected-pages/my-ways-to-save';
import PGEPlusFormPage from '../protected-pages/pge-plus-form';
import MyEnergyUseMain from '../protected-pages/my-energy-use';
import MyPricingPlanMain from '../protected-pages/my-pricing-plan';
import { PGEPlusRouter } from '../protected-pages/pge-plus/PGEPlusRouter';
import RooftopSolarMain from '../protected-pages/rooftop-solar';
import OCPAFormMain from '../protected-pages/oregon-consumer-privacy-act';
import HomeEnergyAnalysisSurvey from '../protected-pages/home-energy-analysis';
import MyProgramsMain from '../protected-pages/my-programs';
import AdditionalProgramsMain from '../protected-pages/additional-programs';

const SecureRouter = () => {
  usePlannedDownTimeRedirect();
  const { contactDataIntercept, isClientProgramPromoModalEnabled } = useContext(
    featureFlagsContext,
  );
  const {
    open,
    contactData,
    setOpen,
    setIsSessionDismissed,
  } = useContactDataInterceptModal(contactDataIntercept);
  const { open: openPromo, setOpen: setOpenPromo } = useClientProgramPromo(
    isClientProgramPromoModalEnabled,
  );
  return (
    <CheckCookiesEnabled>
      <Router basepath="/secure/">
        <PrivateRoute path="/profile/*" component={ProfileRouter} />
        <PrivateRoute path="/account/*" component={AccountRouter} />
        <PrivateRoute path="/payment/*" component={PaymentRouter} />
        <PrivateRoute path="/service/*" component={ServiceRouter} />
        <PrivateRoute path="/view-bill/" component={ViewBillPage} />
        <PrivateRoute path="/programs/*" component={ProgramsRouter} />
        <PrivateRoute path="/ssm/*" component={SSMRouter} />
        <PrivateRoute path="/ess/*" component={EssRouter} />
        <PrivateRoute
          path="/download-multiple-bills/"
          component={ViewAndDownloadBillsPage}
        />
        <PrivateRoute
          path="/peak-time-rebates/*"
          component={PeakTimeRebatesPage}
        />
        <PrivateRoute path="/my-energy-use/*" component={MyEnergyUseMain} />
        <PrivateRoute path="/my-programs/*" component={MyProgramsMain} />
        <PrivateRoute
          path="/additional-programs/*"
          component={AdditionalProgramsMain}
        />
        <PrivateRoute path="/my-ways-to-save/*" component={MyWaysToSaveMain} />
        <PrivateRoute
          path="/home-energy-analysis/*"
          component={HomeEnergyAnalysisSurvey}
        />
        <PrivateRoute path="/my-pricing-plan/*" component={MyPricingPlanMain} />
        <PrivateRoute path="/time-of-day/*" component={TimeOfDayMain} />
        <PrivateRoute path="/rooftop-solar/*" component={RooftopSolarMain} />
        <PrivateRoute
          path="/oregon-consumer-privacy-act/*"
          component={OCPAFormMain}
        />
        <PrivateRoute
          path="/reconnect-service/*"
          component={ReconnectServiceMain}
        />
        <PrivateRoute
          path="/need-more-time-to-pay/*"
          component={NeedMoreTimeToPayPage}
        />
        <PrivateRoute path="/closed-accounts/*" component={ClosedAccounts} />
        <PrivateRoute path="/pge-plus/*" component={PGEPlusRouter} />
        <PrivateRoute path="/pge-plus-form/*" component={PGEPlusFormPage} />
        <PrivateRoute path="/*" component={NotFoundPage} />
      </Router>
      {open && (
        <ContactDataInterceptModal
          open={open}
          onClose={() => setOpen(false)}
          contactData={contactData}
          setIsSessionDismissed={setIsSessionDismissed}
        />
      )}
      {openPromo && (
        <ClientProgramPromoModal
          open={openPromo}
          onClose={() => setOpenPromo?.(false)}
        />
      )}
    </CheckCookiesEnabled>
  );
};

export default SecureRouter;
