import React, { FunctionComponent, useEffect, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme, Paper, Box, Checkbox } from '@material-ui/core';
import TextField from '../text-field';
import colors from '../../themes/main-colors';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ListSubheader from '@material-ui/core/ListSubheader';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useTranslation } from '../../hooks/useTranslation';
import Backdrop from '../backdrop';
import TextLink from '../text-link';
import Button from '../buttons';
import PhoneTextField from '../phone-text-field';
import { parsePhoneNumber } from '../../util/format';
import { UpdateInfoProps } from '../../hooks/useUpdateInfoForm';
import {
  OnlineAccountType,
  AccountRelationship,
  RelationshipType,
} from '../../__generated__/pge-types';
import Alert from '@material-ui/lab/Alert';
import PhoneExtTextField from '../phone-ext-text-field';
import ROUTES from '../../routes';
import ProvinceInputField from '../province-input-field';
import ZipInputField from '../zip-input-field';
import { PersonalInfoCard } from './PersonalInfoCard';
import { QasModal } from '../qas';
import { navigate } from '@reach/router';

const useStyles = makeStyles((theme: Theme) => ({
  contactPhones: {
    '& > div ': {
      display: 'flex',
      flexDirection: 'row',
      gap: '.625rem',
      margin: '0 0 1.25rem 0',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
  },
  phoneField: {
    margin: 0,
    width: '40% !important',
    [theme.breakpoints.down('sm')]: {
      width: '100% !important',
    },
  },
  checkBoxLabel: {
    fontSize: '0.875rem',
  },
  textStyle: {
    marginBottom: 20,
  },
  fullWidth: {
    width: '100%',
  },
}));

interface UpdateInfoFormProps extends UpdateInfoProps {
  path?: string;
}

const UpdateInfoForm: FunctionComponent<UpdateInfoFormProps> = props => {
  const { t, richT } = useTranslation();
  const classes = useStyles();
  const {
    loading,
    signedInUserRelationshipType,
    accountType,
    persons = [],
    serviceAddress = {},
    updateInfoFormProps,
    initialFormState,
    statesAndProvincesGrouping,
    handleFormSubmit,
    isProvinceSelected,
    qasState,
    setQasState,
    handleQasSubmit,
    isMobilePhoneSameAsPrimaryPhone,
    setIsMobilePhoneSameAsPrimaryPhone,
    mobNumber,
  } = props;
  const handleUsePrimaryForMobile = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const usePnpForMobile = e.target.checked;
    setIsMobilePhoneSameAsPrimaryPhone(usePnpForMobile);
    if (usePnpForMobile) {
      updateInfoFormProps.mobilePhone.value = '';
    } else {
      updateInfoFormProps.mobilePhone.value = mobNumber || '';
    }
  };

  const scrollToMailingAddressRef = useRef(false);

  const mailingAddressSectionId = 'mailing-address-form-section';

  const isFormUpdated =
    JSON.stringify(initialFormState) !== JSON.stringify(updateInfoFormProps);

  useEffect(() => {
    if (scrollToMailingAddressRef.current) {
      scrollToMailingAddressRef.current = false;
      document.getElementById(mailingAddressSectionId)?.scrollIntoView();
    }
  }, [scrollToMailingAddressRef.current]);

  const coAppUser = persons.find(
    p => p.personRelationshipType === RelationshipType.CoApplicant,
  );
  const mainUser = persons.find(
    p => p.personRelationshipType === RelationshipType.MainCustomer,
  );

  // redirect user to update personal information page if account type is residential and user is not main customer or co-customer
  useEffect(() => {
    if (
      signedInUserRelationshipType &&
      signedInUserRelationshipType !== AccountRelationship.Coapp &&
      signedInUserRelationshipType !== AccountRelationship.Main &&
      accountType === OnlineAccountType.PgeResidentialAcct
    ) {
      void navigate(ROUTES.UPDATE_PERSONAL_INFORMATION);
    }
  }, [signedInUserRelationshipType]);

  return (
    <>
      {loading && <Backdrop forceOpen />}
      {!loading && (
        <CardContent>
          <Paper style={{ padding: 30, marginBottom: 30 }}>
            <Typography variant="subtitle1">
              {t('MAKE_ANY_CHANGE_INFORMATION')}
            </Typography>

            <Grid container>
              <Grid item xs={12} md={12} style={{ marginTop: 16 }}>
                {persons.map((person, index) => (
                  <Typography variant="h2" key={index}>
                    {person?.fullName}
                  </Typography>
                ))}
              </Grid>
              <Grid item xs={12} md={12} style={{ marginTop: 16 }}>
                <Typography variant="body1">{t('ACCOUNT_NUMBER')}</Typography>
                <Typography variant="body2">{props.accountNumber}</Typography>
              </Grid>
              <Grid item xs={12} md={12} style={{ marginTop: 16 }}>
                <Typography variant="body1">{t('SERVICE_ADDRESS')}</Typography>
                <Typography variant="body2">
                  {serviceAddress?.addressLine1}
                </Typography>
                {serviceAddress?.addressLine2 && (
                  <Typography variant="body2">
                    {serviceAddress?.addressLine2}
                  </Typography>
                )}
                <Typography variant="body2">
                  {serviceAddress?.city} {serviceAddress?.state}{' '}
                  {serviceAddress?.postal}
                </Typography>
              </Grid>

              {accountType === OnlineAccountType.PgeCommercialAcct && (
                <Grid item xs={12} md={12} style={{ marginTop: 16 }}>
                  <Typography variant="body1" style={{ marginBottom: 8 }}>
                    {t('SERVICE_LOCATION_PHONE')}
                  </Typography>
                  <PhoneTextField
                    name={'primaryPhone'}
                    id="serviceLocationPhone"
                    label={t('PRIMARY_CONTACT_PHONE')}
                    style={{ width: '100%', marginTop: 0 }}
                    autoComplete={'telephone'}
                    onChange={updateInfoFormProps?.handleChange}
                    error={!!updateInfoFormProps.primaryPhone.errorMessage}
                    value={updateInfoFormProps.primaryPhone.value}
                    helperText={updateInfoFormProps.primaryPhone.errorMessage}
                    onBlur={(event: any) => {
                      event.target.value = parsePhoneNumber(event.target.value);
                      if (updateInfoFormProps && updateInfoFormProps.onBlur) {
                        updateInfoFormProps.onBlur(event);
                      }
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </Paper>
          {accountType === OnlineAccountType.PgeCommercialAcct && (
            <PersonalInfoCard />
          )}
          {accountType === OnlineAccountType.PgeResidentialAcct && (
            <Paper style={{ padding: 30, marginBottom: 30 }}>
              <Typography variant="h2" className={classes.textStyle}>
                {t('PREFERRED_CONTACT_LANGUAGE')}
              </Typography>
              <Grid item xs={12} md={12}>
                <Select
                  value={updateInfoFormProps.preferredLanguage.value}
                  id="preferredLanguage"
                  name="preferredLanguage"
                  onChange={updateInfoFormProps.handleChange}
                  error={!!updateInfoFormProps.errorMessage}
                  onBlur={updateInfoFormProps.onBlur}
                  variant="outlined"
                  style={{ width: '100%' }}
                >
                  {props.preferredLanguages.map(v => (
                    <MenuItem key={v} value={v}>
                      {t(v)}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Paper>
          )}
          {accountType === OnlineAccountType.PgeResidentialAcct && (
            <Paper style={{ padding: 30, marginBottom: 30 }}>
              <Typography variant="h2" style={{ paddingTop: 20 }}>
                {t('CONTACT_INFORMATION')}
              </Typography>
              <Typography
                variant="subtitle1"
                style={{ margin: '.625rem 0 1.5rem 0' }}
              >
                {t('LETS_KEEP_IN_TOUCH')}
              </Typography>
              <Box className={classes.contactPhones}>
                <Box>
                  <PhoneTextField
                    className={classes.phoneField}
                    name={'primaryPhone'}
                    id="primaryContactUpdateInfo"
                    label={t('PRIMARY_CONTACT')}
                    autoComplete={'telephone'}
                    onChange={updateInfoFormProps?.handleChange}
                    onBlur={(event: any) => {
                      event.target.value = parsePhoneNumber(event.target.value);
                      if (updateInfoFormProps && updateInfoFormProps.onBlur) {
                        updateInfoFormProps.onBlur(event);
                      }
                    }}
                    error={!!updateInfoFormProps.primaryPhone.errorMessage}
                    value={updateInfoFormProps.primaryPhone.value}
                    helperText={updateInfoFormProps.primaryPhone.errorMessage}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        name={'isMobileSameAsPrimary'}
                        value={isMobilePhoneSameAsPrimaryPhone}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          handleUsePrimaryForMobile(e);
                        }}
                        color={'primary'}
                      />
                    }
                    label={t('USE_FOR_MOBILE')}
                    classes={{
                      label: classes.checkBoxLabel,
                    }}
                  />
                </Box>
                {!isMobilePhoneSameAsPrimaryPhone && (
                  <Box>
                    <PhoneTextField
                      className={classes.phoneField}
                      name={'mobilePhone'}
                      id="mobilePhoneUpdateInfo"
                      label={t('MOBILE_PHONE')}
                      autoComplete={'telephone'}
                      onChange={updateInfoFormProps?.handleChange}
                      value={updateInfoFormProps.mobilePhone.value}
                      error={!!updateInfoFormProps.mobilePhone.errorMessage}
                      helperText={updateInfoFormProps.mobilePhone.errorMessage}
                    />
                  </Box>
                )}
                <Box>
                  <PhoneTextField
                    className={classes.phoneField}
                    name={'alternatePhone'}
                    id="alternatePhoneUpdateInfo"
                    label={t('ALTERNATE_PHONE')}
                    autoComplete={'telephone'}
                    margin={'none'}
                    onChange={updateInfoFormProps?.handleChange}
                    error={!!updateInfoFormProps.alternatePhone.errorMessage}
                    value={updateInfoFormProps.alternatePhone.value}
                    helperText={updateInfoFormProps.alternatePhone.errorMessage}
                  />
                </Box>
              </Box>
              <Typography
                variant="body2"
                style={{ paddingTop: 20, marginBottom: 25 }}
              >
                {t('CONTACT_INFO_FOOTER')}
              </Typography>
            </Paper>
          )}
          {accountType === OnlineAccountType.PgeResidentialAcct && (
            <Paper
              style={{ padding: 30, color: colors.noirBlur, marginBottom: 30 }}
            >
              <Typography variant="h2">{t('EMAIL')}</Typography>
              <Typography
                variant="subtitle1"
                style={{ margin: '.625rem 0 1rem 0' }}
              >
                {t('CHANGE_EMAIL_COPY')}
              </Typography>
              <Typography variant="subtitle1">
                {t('PRIMARY')}:{' '}
                <strong>{updateInfoFormProps?.email.value}</strong>
                <TextLink
                  to={ROUTES.CHANGE_EMAIL}
                  plain
                  style={{ marginLeft: '1em', fontSize: '0.875rem' }}
                >
                  {t('EDIT')}
                </TextLink>
              </Typography>
            </Paper>
          )}
          <Paper
            style={{ padding: 30, marginBottom: 30 }}
            id={mailingAddressSectionId}
          >
            <Typography variant="h2">{t('MAILING_ADDRESS')}</Typography>
            <Typography
              variant="body2"
              style={{ marginTop: 8, marginBottom: 16 }}
            >
              {t('MAILING_ADDRESS_CHANGE_MOVE_SERVICE_NOTICE_UPDATE_INFO')}
              <TextLink plain to={ROUTES.MOVE_SERVICE}>
                {t('MOVE_SERVICE')}.
              </TextLink>
            </Typography>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12} md={12}>
                <TextField
                  name={'address'}
                  id="streetAddressUpdateInfo"
                  label={t('STREET_ADDRESS_UPDATE_INFO_LABEL')}
                  inputProps={{ maxLength: 60 }}
                  style={{ width: '100%' }}
                  onChange={updateInfoFormProps.handleChange}
                  error={!!updateInfoFormProps.address.errorMessage}
                  helperText={updateInfoFormProps.address.errorMessage}
                  value={
                    typeof updateInfoFormProps?.address?.value === 'string'
                      ? updateInfoFormProps?.address?.value
                      : updateInfoFormProps?.address?.value || ''
                  }
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  name={'city'}
                  id="cityUpdateInfo"
                  label={t('CITY')}
                  style={{ width: '100%' }}
                  inputProps={{ maxLength: 20 }}
                  onChange={updateInfoFormProps.handleChange}
                  error={!!updateInfoFormProps.city.errorMessage}
                  helperText={updateInfoFormProps.city.errorMessage}
                  value={
                    typeof updateInfoFormProps?.city?.value === 'string'
                      ? updateInfoFormProps?.city?.value
                      : updateInfoFormProps?.city?.value || ''
                  }
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Select
                  name={'state'}
                  id="stateUpdateInfo"
                  onChange={updateInfoFormProps.handleChange}
                  error={!!updateInfoFormProps.state.errorMessage}
                  value={
                    typeof updateInfoFormProps?.state?.value === 'string'
                      ? updateInfoFormProps?.state?.value.trim()
                      : updateInfoFormProps?.state?.value || ''
                  }
                  variant="outlined"
                  displayEmpty
                  style={{ width: '100%' }}
                >
                  <MenuItem value="">{t('STATE_PROVINCE_LABEL')}</MenuItem>
                  <ListSubheader style={{ backgroundColor: '#fff' }}>
                    {t('UNITED_STATES')}
                  </ListSubheader>
                  {statesAndProvincesGrouping.unitedStates.map(stateValue => (
                    <MenuItem key={stateValue} value={stateValue}>
                      {stateValue}
                    </MenuItem>
                  ))}
                  <ListSubheader style={{ backgroundColor: '#fff' }}>
                    {t('CANADA')}
                  </ListSubheader>
                  {statesAndProvincesGrouping.canada.map(stateValue => (
                    <MenuItem key={stateValue} value={stateValue}>
                      {stateValue}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} md={4}>
                {isProvinceSelected ? (
                  <ProvinceInputField
                    name={'zip'}
                    id="zipCodeUpdateInfo"
                    label={t('ZIP_CODE_UPDATE_INFO_LABEL')}
                    style={{ width: '100%' }}
                    onChange={updateInfoFormProps.handleChange}
                    error={!!updateInfoFormProps.zip.errorMessage}
                    helperText={updateInfoFormProps.zip.errorMessage}
                    value={
                      typeof updateInfoFormProps?.zip?.value === 'string'
                        ? updateInfoFormProps?.zip?.value.trim()
                        : updateInfoFormProps?.zip?.value || ''
                    }
                  />
                ) : (
                  <ZipInputField
                    name={'zip'}
                    id="zipCodeUpdateInfo"
                    label={t('ZIP_CODE_UPDATE_INFO_LABEL')}
                    style={{ width: '100%' }}
                    onChange={updateInfoFormProps.handleChange}
                    error={!!updateInfoFormProps.zip.errorMessage}
                    helperText={updateInfoFormProps.zip.errorMessage}
                    value={
                      typeof updateInfoFormProps?.zip?.value === 'string'
                        ? updateInfoFormProps?.zip?.value.trim()
                        : updateInfoFormProps?.zip?.value || ''
                    }
                  />
                )}
              </Grid>
            </Grid>

            {accountType === OnlineAccountType.PgeCommercialAcct && (
              <>
                <Divider style={{ marginTop: 16, marginBottom: 32 }} />

                <Typography variant="h2" style={{ marginBottom: 8 }}>
                  {t('CONTACT_INFORMATION')}
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <TextField
                      id="contactNameUpdateInfo"
                      name={'contactName'}
                      label={t('CONTACT_NAME')}
                      style={{ width: '100%' }}
                      inputProps={{ maxLength: 90 }}
                      onChange={updateInfoFormProps.handleChange}
                      error={!!updateInfoFormProps.contactName.errorMessage}
                      helperText={updateInfoFormProps.contactName.errorMessage}
                      value={updateInfoFormProps.contactName.value}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <PhoneTextField
                      name={'contactPrimaryPhone'}
                      id="contactPrimaryPhoneUpdateInfo"
                      label={t('CONTACT_PHONE_HEAD_LABEL')}
                      style={{ width: '100%', marginTop: 0 }}
                      autoComplete={'telephone'}
                      onChange={updateInfoFormProps?.handleChange}
                      error={
                        !!updateInfoFormProps.contactPrimaryPhone.errorMessage
                      }
                      value={updateInfoFormProps.contactPrimaryPhone.value}
                      helperText={
                        updateInfoFormProps.contactPrimaryPhone.errorMessage
                      }
                      onBlur={(event: any) => {
                        event.target.value = parsePhoneNumber(
                          event.target.value,
                        );
                        if (updateInfoFormProps && updateInfoFormProps.onBlur) {
                          updateInfoFormProps.onBlur(event);
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <PhoneExtTextField
                      name={'primaryPhoneExt'}
                      id="extUpdateInfo"
                      label={t('EXT')}
                      style={{ width: '100%', marginTop: 0 }}
                      onChange={updateInfoFormProps.handleChange}
                      error={!!updateInfoFormProps.primaryPhoneExt.errorMessage}
                      helperText={
                        updateInfoFormProps.primaryPhoneExt.errorMessage
                      }
                      value={updateInfoFormProps?.primaryPhoneExt?.value}
                      onBlur={(e: any) => {
                        updateInfoFormProps?.onBlur(e);
                        updateInfoFormProps?.onBlur({
                          persist: () => null,
                          target: {
                            name: 'contactPrimaryPhone',
                            value:
                              updateInfoFormProps?.contactPrimaryPhone?.value,
                          },
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      name={'bookKeeperName'}
                      id="bookKeeperNameUpdateInfo"
                      label={t('BOOKKEEPER_NAME')}
                      style={{ width: '100%' }}
                      onChange={updateInfoFormProps.handleChange}
                      error={!!updateInfoFormProps.bookKeeperName.errorMessage}
                      helperText={
                        updateInfoFormProps.bookKeeperName.errorMessage
                      }
                      value={updateInfoFormProps?.bookKeeperName?.value}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <PhoneTextField
                      name={'bookKeeperPhone'}
                      id="bookKeeperPhoneUpdateInfo"
                      label={t('BOOKKEEPER_PHONE')}
                      style={{ width: '100%', marginTop: 0 }}
                      autoComplete={'telephone'}
                      onChange={updateInfoFormProps?.handleChange}
                      error={!!updateInfoFormProps.bookKeeperPhone.errorMessage}
                      value={updateInfoFormProps.bookKeeperPhone.value}
                      helperText={
                        updateInfoFormProps.bookKeeperPhone.errorMessage
                      }
                      onBlur={(event: any) => {
                        event.target.value = parsePhoneNumber(
                          event.target.value,
                        );
                        if (updateInfoFormProps && updateInfoFormProps.onBlur) {
                          updateInfoFormProps.onBlur(event);
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <PhoneExtTextField
                      name={'bookKeeperPhoneExt'}
                      id="bookKeeperPhoneExtUpdateInfo"
                      label={t('EXT')}
                      style={{ width: '100%', marginTop: 0 }}
                      onChange={updateInfoFormProps.handleChange}
                      onBlur={(e: any) => {
                        updateInfoFormProps?.onBlur(e);
                        updateInfoFormProps?.onBlur({
                          persist: () => null,
                          target: {
                            name: 'bookKeeperPhone',
                            value: updateInfoFormProps.bookKeeperPhone.value,
                          },
                        });
                      }}
                      error={
                        !!updateInfoFormProps.bookKeeperPhoneExt.errorMessage
                      }
                      helperText={
                        updateInfoFormProps.bookKeeperPhoneExt.errorMessage
                      }
                      value={updateInfoFormProps?.bookKeeperPhoneExt?.value}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </Paper>

          {accountType === OnlineAccountType.PgeCommercialAcct && (
            <Paper
              style={{ padding: 30, color: colors.noirBlur, marginBottom: 30 }}
            >
              <Typography variant="h2">{t('EMAIL')}</Typography>
              <Typography
                variant="subtitle1"
                style={{ margin: '.625rem 0 1rem 0' }}
              >
                {t('CHANGE_EMAIL_COPY')}
              </Typography>
              <Typography variant="subtitle1">
                {t('PRIMARY')}:{' '}
                <strong>{updateInfoFormProps?.email.value}</strong>
                <TextLink
                  to={ROUTES.CHANGE_EMAIL}
                  plain
                  style={{ marginLeft: '1em', fontSize: '0.875rem' }}
                >
                  {t('EDIT')}
                </TextLink>
              </Typography>
            </Paper>
          )}
          {accountType === OnlineAccountType.PgeCommercialAcct && (
            <Paper style={{ padding: 30, marginBottom: 30 }}>
              <Typography variant="h2" className={classes.textStyle}>
                {t('PREFERRED_CONTACT_LANGUAGE')}
              </Typography>
              <Grid item xs={12} md={12}>
                <Select
                  value={updateInfoFormProps.preferredLanguage.value}
                  id="preferredLanguage"
                  name="preferredLanguage"
                  onChange={updateInfoFormProps.handleChange}
                  error={!!updateInfoFormProps.errorMessage}
                  onBlur={updateInfoFormProps.onBlur}
                  variant="outlined"
                  style={{ width: '100%' }}
                >
                  {props.preferredLanguages.map(v => (
                    <MenuItem key={v} value={v}>
                      {t(v)}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Paper>
          )}
          {accountType === OnlineAccountType.PgeResidentialAcct && (
            <Paper style={{ padding: 30, color: colors.noirBlur }}>
              <Typography variant="h2" style={{ marginBottom: 8 }}>
                {t('CUSTOMER_NAME')}
              </Typography>

              <Typography
                variant="body2"
                style={{ marginBottom: 24, color: colors.noirBlur }}
              >
                {t('UPDATE_INFO_CUSTOMER_SECTION_KICKER_TEXT')}
              </Typography>

              <Grid container spacing={3}>
                <Grid item xs={12} spacing={3}>
                  <Typography
                    variant="body2"
                    css={{
                      backgroundColor: colors.lightGray3,
                      fontSize: '16px',
                      padding: '5px 15px',
                      borderRadius: '4px',
                      '& a': { textDecoration: 'none' },
                    }}
                  >
                    {richT('UPDATE_INFO_CUSTOMER_SECTION_GRAY_BOX_TEXT')}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Select
                    value={updateInfoFormProps.reasonForChange.value}
                    id="reasonForChangeUpdateInfo"
                    name="reasonForChange"
                    onChange={updateInfoFormProps.handleChange}
                    error={!!updateInfoFormProps.reasonForChange.errorMessage}
                    onBlur={updateInfoFormProps.onBlur}
                    variant="outlined"
                    style={{ width: '100%' }}
                    displayEmpty
                  >
                    <MenuItem value={''}>
                      <span style={{ color: colors.darkGray2 }}>
                        {t('REASON_FOR_CHANGE')}
                      </span>
                    </MenuItem>
                    {props.ChangeNameReasons.map((v, i) => (
                      <MenuItem key={i} value={v.value}>
                        {t(v.label)}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText style={{ color: colors.roseRed }}>
                    {updateInfoFormProps.reasonForChange.errorMessage}
                  </FormHelperText>
                </Grid>
                <Grid style={{ width: '100%', padding: '0px 12px 4px 12px' }}>
                  {updateInfoFormProps.reasonForChange.value && (
                    <Box>
                      <Alert severity="info">
                        {
                          props.ChangeNameAllowedFields[
                            updateInfoFormProps.reasonForChange?.value as string
                          ]
                        }
                      </Alert>
                    </Box>
                  )}
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    name={'firstName'}
                    id="nameUpdateInfo"
                    label={t('FIRST_NAME')}
                    className={classes.fullWidth}
                    inputProps={{ maxLength: 90 }}
                    onChange={updateInfoFormProps.handleChange}
                    error={!!updateInfoFormProps.firstName.errorMessage}
                    helperText={updateInfoFormProps.firstName.errorMessage}
                    value={updateInfoFormProps.firstName.value || ''}
                    onBlur={updateInfoFormProps.onBlur}
                    disabled={
                      !updateInfoFormProps.reasonForChange?.value ||
                      updateInfoFormProps.reasonForChange?.value ===
                        t('MARRIAGE') ||
                      updateInfoFormProps.reasonForChange?.value ===
                        t('DIVORCE')
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    name={'middleName'}
                    id="nameUpdateInfo"
                    label={t('MIDDLE_NAME')}
                    className={classes.fullWidth}
                    inputProps={{ maxLength: 90 }}
                    onChange={updateInfoFormProps.handleChange}
                    error={!!updateInfoFormProps.middleName.errorMessage}
                    helperText={updateInfoFormProps.middleName.errorMessage}
                    value={updateInfoFormProps.middleName.value || ''}
                    onBlur={updateInfoFormProps.onBlur}
                    disabled={
                      !updateInfoFormProps.reasonForChange?.value ||
                      updateInfoFormProps.reasonForChange?.value ===
                        t('MARRIAGE') ||
                      updateInfoFormProps.reasonForChange?.value ===
                        t('DIVORCE')
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    name={'lastName'}
                    id="nameUpdateInfo"
                    label={t('LAST_NAME')}
                    className={classes.fullWidth}
                    inputProps={{ maxLength: 90 }}
                    onChange={updateInfoFormProps.handleChange}
                    error={!!updateInfoFormProps.lastName.errorMessage}
                    helperText={updateInfoFormProps.lastName.errorMessage}
                    value={updateInfoFormProps.lastName.value || ''}
                    onBlur={updateInfoFormProps.onBlur}
                    disabled={!updateInfoFormProps.reasonForChange?.value}
                  />
                </Grid>
              </Grid>

              {coAppUser ? (
                <>
                  <Divider style={{ marginTop: 35, marginBottom: 30 }} />

                  <Typography variant="h2" style={{ marginBottom: 8 }}>
                    {t('UPDATE_INFO_CO_CUSTOMER_NAME')}
                  </Typography>

                  <Typography
                    variant="body2"
                    style={{ marginBottom: 24, color: colors.noirBlur }}
                  >
                    {t('UPDATE_INFO_CO_CUSTOMER_SECTION_KICKER_TEXT')}
                  </Typography>

                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <Select
                        value={
                          updateInfoFormProps.coCustomerReasonForChange
                            ?.value || ''
                        }
                        id="coCustomerReasonForChangeUpdateInfo"
                        name="coCustomerReasonForChange"
                        onChange={updateInfoFormProps.handleChange}
                        onBlur={updateInfoFormProps.onBlur}
                        error={
                          !!updateInfoFormProps.coCustomerReasonForChange
                            ?.errorMessage
                        }
                        variant="outlined"
                        style={{ width: '100%' }}
                        displayEmpty
                      >
                        <MenuItem value={''}>
                          <span style={{ color: colors.darkGray2 }}>
                            {t('REASON_FOR_CHANGE')}
                          </span>
                        </MenuItem>
                        {props.ChangeNameReasons.map((changeReason, index) => (
                          <MenuItem key={index} value={changeReason.value}>
                            {t(changeReason.label)}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText style={{ color: colors.roseRed }}>
                        {
                          updateInfoFormProps.coCustomerReasonForChange
                            ?.errorMessage
                        }
                      </FormHelperText>
                    </Grid>
                    <Grid
                      style={{ width: '100%', padding: '0px 12px 4px 12px' }}
                    >
                      {updateInfoFormProps.coCustomerReasonForChange?.value && (
                        <Box>
                          <Alert severity="info">
                            {
                              props.ChangeNameAllowedFields[
                                updateInfoFormProps.coCustomerReasonForChange
                                  ?.value as string
                              ]
                            }
                          </Alert>
                        </Box>
                      )}
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        name={'coCustomerFirstName'}
                        id="nameUpdateInfo"
                        label={t('FIRST_NAME')}
                        className={classes.fullWidth}
                        inputProps={{ maxLength: 90 }}
                        onChange={updateInfoFormProps.handleChange}
                        error={
                          !!updateInfoFormProps.coCustomerFirstName
                            ?.errorMessage
                        }
                        helperText={
                          updateInfoFormProps.coCustomerFirstName?.errorMessage
                        }
                        value={
                          updateInfoFormProps.coCustomerFirstName?.value || ''
                        }
                        onBlur={updateInfoFormProps.onBlur}
                        disabled={
                          !updateInfoFormProps.coCustomerReasonForChange
                            ?.value ||
                          updateInfoFormProps.coCustomerReasonForChange
                            ?.value === t('MARRIAGE') ||
                          updateInfoFormProps.coCustomerReasonForChange
                            ?.value === t('DIVORCE')
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        name={'coCustomerMiddleName'}
                        id="nameUpdateInfo"
                        label={t('MIDDLE_NAME')}
                        className={classes.fullWidth}
                        inputProps={{ maxLength: 90 }}
                        onChange={updateInfoFormProps.handleChange}
                        error={
                          !!updateInfoFormProps.coCustomerMiddleName
                            ?.errorMessage
                        }
                        helperText={
                          updateInfoFormProps.coCustomerMiddleName?.errorMessage
                        }
                        value={
                          updateInfoFormProps.coCustomerMiddleName?.value || ''
                        }
                        onBlur={updateInfoFormProps.onBlur}
                        disabled={
                          !updateInfoFormProps.coCustomerReasonForChange
                            ?.value ||
                          updateInfoFormProps.coCustomerReasonForChange
                            ?.value === t('MARRIAGE') ||
                          updateInfoFormProps.coCustomerReasonForChange
                            ?.value === t('DIVORCE')
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        name={'coCustomerLastName'}
                        id="nameUpdateInfo"
                        label={t('LAST_NAME')}
                        className={classes.fullWidth}
                        inputProps={{ maxLength: 90 }}
                        onChange={updateInfoFormProps.handleChange}
                        error={
                          !!updateInfoFormProps.coCustomerLastName?.errorMessage
                        }
                        helperText={
                          updateInfoFormProps.coCustomerLastName?.errorMessage
                        }
                        value={
                          updateInfoFormProps.coCustomerLastName?.value || ''
                        }
                        onBlur={updateInfoFormProps.onBlur}
                        disabled={
                          !updateInfoFormProps.coCustomerReasonForChange?.value
                        }
                      />
                    </Grid>
                  </Grid>
                </>
              ) : null}
            </Paper>
          )}
          <Grid container style={{ marginTop: 24 }}>
            <Grid item xs={12} lg={12}>
              <Grid container justify="space-between">
                <Grid item>
                  <TextLink plain to={ROUTES.ACCOUNT}>
                    {t('CANCEL')}
                  </TextLink>
                </Grid>
                <Grid item>
                  <Button
                    onClick={() => {
                      handleFormSubmit();
                    }}
                    disabled={!isFormUpdated}
                    size={'large'}
                  >
                    {t('SAVE')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      )}
      {qasState && (
        <QasModal
          results={qasState.results}
          onChange={address => {
            setQasState(null);
            handleQasSubmit(true, address);
          }}
          address={qasState.params}
          onSearch={(params, results) => setQasState({ params, results })}
          onEdit={() => {
            scrollToMailingAddressRef.current = true;
            setQasState(null);
          }}
          onUseEntered={() => {
            handleQasSubmit(false);
          }}
        />
      )}
    </>
  );
};

export default UpdateInfoForm;
